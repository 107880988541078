import {useState, useEffect} from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { PoweroffOutlined, GoldOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Descriptions } from 'antd';
import moment from 'moment';
import Model from '../../model';
import cocytiegVertical from '../../assets/cocytiegVertical.png'
import sncVertical from '../../assets/sncVertical.png'
import rednacecytVertical from '../../assets/rednacecytVertical.png'
import './stilos.scss';

const defaultDetalle = {
	state: "",
	name: "",
	position: "",
	phone: "",
	email: "",
	activity_name: "",
	modality: 1,
	speaker_name: "",
	duration: "",
	age: "",
	dateTime: moment(),
}

const BtnSide = ({texto,icono,activo,onClick}) => (
  <div onClick={onClick} className={"btn-side"+(activo?' btn-side__activo':'')} >
    {icono}<span>{texto}</span>
  </div>
)

const cierroSesion = () => Model.mevoyALV();

function Detalle(){
	let navigate = useNavigate();
	let {idRegistro} = useParams();
	const [estoyEnLicencias, setEstoyEnLicencias] = useState(false);
	const [data, setData] = useState(defaultDetalle);

	useEffect(()=>{
		Model.observoStatusLogeo(examinoExistencia)
	},[]);

	const examinoExistencia = (user) => { 
	  if (!user) {
	    navigate('/home',{replace:true})
	  }else{
	    getRegister(idRegistro)
	  }
	}

	const getRegister = async(id) => {
		let register = await Model.getForm(id);
		console.log('register', register);
		setData(register);
	}

	const abroLicencias=()=>{
	  setEstoyEnLicencias(true);
	  navigate('/dashboard', {replace:true});
	}

	const refreshTable = () => {
		getRegister();
	}

	return(<div className='super-container' >
	      <div className='side-container' >
	        <div className='logo-container' >
	          <img src={sncVertical} height="50px"/>
		  			<img src={cocytiegVertical} height="50px"/>
		  			<img src={rednacecytVertical} height="50px"/>
	        </div>
	        <div className='list-btns-side' >
	          <BtnSide onClick={abroLicencias} activo={estoyEnLicencias} icono={<GoldOutlined/>} texto="Registros" />
	          <BtnSide onClick={cierroSesion} icono={<PoweroffOutlined/>} texto="Cerrar Sesión" />
	        </div>
	      </div>
	      <div className='principal-container' >
	        <div className="table-container">
	        	<div className="back-button">
	        		<ArrowLeftOutlined onClick={abroLicencias}/>
	        		<span onClick={abroLicencias}>Regresar</span>
	        	</div>
	        	<Descriptions title="Registro de Actividad" bordered layout="vertical">
	        		<Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Estado">{data.state}</Descriptions.Item>
	        		<Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Nombre del enlace desigando para coordinar la actividad" span={2}>{data.name}</Descriptions.Item>
	        		<Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Cargo">{data.position}</Descriptions.Item>
	        		<Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Correo electrónico">{data.email}</Descriptions.Item>
	        		<Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Teléfono">{data.phone}</Descriptions.Item>
	        		<Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Actividad que aportará a la 2da Semana Nacional del Conocimiento" span={3}>{data.activity_name}</Descriptions.Item>
	        		<Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Modalidad">{data.modality === 1 ? "Presencial" : "Virtual"}</Descriptions.Item>
	        		<Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Speakers, Expositores o Talleristas que participarán" span={2}>{data.speaker_name}</Descriptions.Item>
	        		<Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Duración">{data.duration}</Descriptions.Item>
	        		<Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Edades a las que está dirigida la actividad">{data.age}</Descriptions.Item>
	        		<Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Fecha propuesta">{moment.unix(data.dateTime).format("DD/MM/YYYY HH:mm")}</Descriptions.Item>
	        	</Descriptions>
	        </div>
	        <div className='footer' >
	          © {new Date().getFullYear()} - COCYTIEG
	        </div>
	      </div>
	   </div>)
}

export default Detalle;