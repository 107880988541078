import {useState, useEffect} from 'react';
import { useNavigate  } from "react-router-dom";
import { Input, message, Table, Button } from 'antd';
import { PoweroffOutlined, GoldOutlined } from '@ant-design/icons';
import moment from 'moment';
import Model from '../../model';
import cocytiegVertical from '../../assets/cocytiegVertical.png'
import sncVertical from '../../assets/sncVertical.png'
import rednacecytVertical from '../../assets/rednacecytVertical.png'
import './stilos.scss';

const BtnSide = ({texto,icono,activo,onClick}) => (
  <div onClick={onClick} className={"btn-side"+(activo?' btn-side__activo':'')} >
    {icono}<span>{texto}</span>
  </div>
)

const cierroSesion = () => Model.mevoyALV();

function Dashboard(){
	let navigate = useNavigate();
	const [estoyEnLicencias, setEstoyEnLicencias] = useState(true);
	const [data, setData] = useState([]);

	useEffect(()=>{
		Model.observoStatusLogeo(examinoExistencia)
	},[]);

	const columns = [
		{
			title: "Estado",
			dataIndex: 'state',
			key: 'state',
		},
		{
			title: "Nombre del enlace",
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: "Cargo",
			dataIndex: 'position',
			key: 'position',
		},
		{
			title: "Actividad",
			dataIndex: 'activity_name',
			key: 'activity_name',
		},
		{
			title: "Modalidad",
			dataIndex: 'modality',
			key: 'modality',
			render: (modalidad) => <span>{modalidad === 1 ? "Presencial" : "Virtual"}</span>
		},
		{
			title: "Fecha",
			dataIndex: 'dateTime',
			key: 'dateTime',
			filters: [
			{
				text: "Todos",
				value: "Todos",
			},
			{
				text: "07/11/2022",
				//value: moment("07/11/2022", "DD/MM/YYYY"),
				value: "07/11/2022",
			},
			{
				text: "08/11/2022",
				value: "08/11/2022",
			},
			{
				text: "09/11/2022",
				value: "09/11/2022",
			},
			{
				text: "10/11/2022",
				value: "10/11/2022",
			},
			{
				text: "11/11/2022",
				value: "11/11/2022",
			},
			],
			onFilter: (value, record) => {
				return value === "Todos" || moment.unix(record.dateTime).format("DD/MM/YYYY").indexOf(value) === 0
			},
			render: (fecha) => <span>{moment.unix(fecha).format("DD/MM/YYYY HH:mm")}</span>
		},
		{
			title: "Detalle",
			key: 'action',
			render: (_, record) => (
				<Button onClick={()=>{navigate(`/${record.id}`, {replace:true})}}>Ver detalle</Button>
			)
		}
	]

	const examinoExistencia = (user) => { 
	  if (!user) {
	    navigate('/home',{replace:true})
	  }else{
	    getAllRegisters();
	  }
	}

	const getAllRegisters = async() => {
		let forms = await Model.getForms();
		forms = [...forms].sort((a,b)=>a.dateTime - b.dateTime);
		setData(forms);
	}

	const abroLicencias=()=>{
	  setEstoyEnLicencias(true);
	  navigate('/dashboard', {replace:true});
	}

	const refreshTable = () => {
		getAllRegisters();
	}

	return(<div className='super-container' >
	      <div className='side-container' >
	        <div className='logo-container' >
	          <img src={sncVertical} height="50px"/>
		  			<img src={cocytiegVertical} height="50px"/>
		  			<img src={rednacecytVertical} height="50px"/>
	        </div>
	        <div className='list-btns-side' >
	          <BtnSide onClick={abroLicencias} activo={estoyEnLicencias} icono={<GoldOutlined/>} texto="Registros" />
	          <BtnSide onClick={cierroSesion} icono={<PoweroffOutlined/>} texto="Cerrar Sesión" />
	        </div>
	      </div>
	      <div className='principal-container' >
	        <div className="table-container">
	        	<div className="refresh-button">
	        		<Button type="primary" style={{marginRight: "10px"}} onClick={refreshTable}>Actualizar tabla</Button>
	        	</div>
	        	<Table columns={columns} dataSource={data} pagination={false} scroll={{y: `calc(100vh - 82px - 55px)`}}/>
	        </div>
	        <div className='footer' >
	          © {new Date().getFullYear()} - COCYTIEG
	        </div>
	      </div>
	   </div>)
}

export default Dashboard;