import logo from './logo.svg';
import './App.scss';
import { useEffect } from "react";
import {Routes, Route, useNavigate } from "react-router-dom";
import { ActivityIndicator } from "./componentes";
import Model from "./model";
import Home from './Pantallas/Home';
import Login from './Pantallas/Login';
import Dashboard from './Pantallas/Dashboard';
import Detalle from './Pantallas/Detalle';
import 'antd/dist/antd.css';

const LoadinContainer = () => {
  let navigate = useNavigate();
  useEffect(()=>{
    Model.observoStatusLogeo(examinoExistencia)
    // eslint-disable-next-line
  },[]);

  const examinoExistencia = (user) => { 
    if (!user) {
      navigate('/home',{replace:true})
    }else{
      navigate('/dashboard',{replace:true})
    }
  }
  return (
    <div className="loading-screen-container" >
      <ActivityIndicator />
    </div>
  )
}

function App() {
  return (
    <Routes>
      <Route path="/" element={<LoadinContainer/>}/>
      <Route path="/home/*" element={<Home />}/>
      <Route path="/login" element={<Login />}/>
      <Route path="/dashboard" element={<Dashboard />}/>
      <Route path="/:idRegistro" element={<Detalle/>}/>
    </Routes>
  );
}

export default App;
