import './stilos.scss'

function InputContainer({label,children,className = ''}) {
  return (
    <div className={'input-container '+className} >
      {label&&<label>{label}</label>}
      {children}
    </div>
  )
}

export default InputContainer