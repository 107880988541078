import firebase from './firebaseConfig';
import { getFirestore, setDoc, doc, query, getDoc, getDocs, collection, addDoc} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { 
  onAuthStateChanged,
	signOut,
	signInWithEmailAndPassword
} from "firebase/auth";

const db = getFirestore(firebase);
const auth = getAuth(firebase)
class Model{
	observoStatusLogeo = (callback) =>{
	  this.authStateChanged = onAuthStateChanged(auth,callback);
	};
	limpioObservoStatusLogeo = () =>{ 
	  this.authStateChanged()
	  this.authStateChanged = null;
	};
		inicioSesion = async({email,password})=>{
			return signInWithEmailAndPassword(auth, email, password).then((usuario) =>usuario.user).catch((error) =>{
	      switch(error.code){
	        case 'auth/too-many-requests':
	          return {error:true,tipo:2,mensaje:'Tu cuenta ha sido desactivada temporalmente debido a múltiples intentos fallidos, por favor intente más tarde'};
	        case 'auth/user-not-found':
	          return {error:true,tipo:2,mensaje:'Usuario no encontrado'};
	        case 'auth/wrong-password':
	          return {error:true,tipo:3,mensaje:'Contraseña incorrecta'};
	        case 'auth/user-disabled':
	          return {error:true,tipo:4,mensaje:'Usuario desactivado'};
	        default:
	          return {error:true,tipo:5,mensaje:'Ocurrió un error inesperado'};
	      }
	    });  
		}
		mevoyALV=()=>signOut(auth);
	getForms = async() => {
		try{
			const querySnapshot = await getDocs(collection(db, "Forms"));
			/*querySnapshot.forEach((doc)=>{
				//
			})*/
			return querySnapshot.docs.map((obj)=>({id:obj.id, ...obj.data()}))
		}catch(e){
			console.log('error', e);
		}
	}

	getForm = async(id) => {
		try{
			const registro = await getDoc(doc(db, "Forms", id));
			return registro.data();
		}catch(e){
			console.log('error', e);
		}
	}

	saveForm = async(data) => {
		try{
			await addDoc(collection(db, "Forms"), {
				...data
			})
			return true;
		}catch(e){
			console.log('error', e);
			return false;
		}
	}
}

Model= new Model();

export default Model;