import React,{useState,useEffect, Fragment} from 'react';
import estados from '../../utils/estados';
import headerLogos from '../../assets/header.jpeg'
import cocytiegVertical from '../../assets/cocytiegVertical.png'
import sncVertical from '../../assets/sncVertical.png'
import rednacecytVertical from '../../assets/rednacecytVertical.png'
import type { RadioChangeEvent } from 'antd';
import { Divider, Select, Input, Button, Space, Radio, DatePicker, message } from 'antd';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import Model from "../../model";
import './stilos.scss';

const { Option } = Select;
//const { RangePicker } = DatePicker;

const defaultData = {
	state: undefined,
	name: "",
	position: "",
	phone: "",
	email: "",
	activity_name: "",
	mode: 1,
	speaker_name: "",
	duration: "",
	age: "",
	day: "07",
	hour: "10",
	dateTime: moment().unix(),
}

const range = (start, end) => {
  const result = [];

  for (let i = start; i < end; i++) {
    result.push(i);
  }

  return result;
};

function Home(){
	const [stateSelected, setStateSelected] = useState(false);
	const [data, setData] = useState(defaultData);
	const [registers, setRegisters] = useState([]);
	const [disabledHours, setDisabledHours] = useState([0, 1, 2, 3, 4, 5 , 6, 7, 21, 22, 23]);

	useEffect(()=>{
		downloadBdForms();
	}, [])

	const downloadBdForms = async() => {
		let bdForms = await Model.getForms();
		setRegisters(bdForms);
		let hoursOTD = bdForms.filter(frm=>frm.day === "07").map(fm=>Number(fm.hour));
		setDisabledHours([...hoursOTD, 0, 1, 2, 3, 4, 5 , 6, 7, 8, 9, 21, 22, 23])
	}

	const disabledDate = (current) => {
  	// Can not select days before today and today
  	//return current && current < moment().endOf('day');
  	return current < moment("20221107", "YYYYMMDD") || current > moment("20221112", "YYYYMMDD");
  	//return current < moment("20221101", "YYYYMMDD") || current > moment("20221130", "YYYYMMDD");
	};

	const disabledDateTime = () => ({
	  disabledHours: () => disabledHours,
	  disabledMinutes: () => range(0, 60),
	});

	const onChangeModalidad = (e: RadioChangeEvent) => {
		////console.log('radio checked', e.target.value);
		setData({...data, mode: e.target.value});
	};

	const handleSiguiente = () => {
		setStateSelected(true);
	}

	const handleBack = () => {
		setStateSelected(false);
	}

	const handleSend = async() => {
		//setStateSelected(true);
		if(validateFields()){
			//console.log('data', data);
			let forms = await Model.getForms();
			//console.log('forms', forms);
			let hoursOTD = forms.filter(frm=>frm.day === data.day).map(fm=>fm.hour);
			//console.log('hoursOTD', hoursOTD);
			if(hoursOTD.includes(data.hour)){
				message.warn("El horario seleccionado ya se encuentra ocupado");
				return false;
			}
			await Model.saveForm(data);
			message.success("Registro guardado exitosamente.")
			setData(defaultData);
			window.scrollTo(0, 0);
			//window.location.reload(false);
		}
	}

	const handleStateChange = (value) => {
		setData({...data, state: value});
	}

	const handleDayHour = () => {
		//console.log('onOk');
	}

	const handleDateChange = (date, dateString) => {
		//console.log('date', date);
		setData({...data, hour: moment(date).format("HH"), day: moment(date).format("DD"), dateTime: moment(date).unix(), dateTimeString: moment(date).format()})
		//setData({...data, hour: moment()})
	}

	const handlePanelChange = (value, mode) => {
		//console.log('panel', value);
		//console.log('panelMode', mode);
		//setData({...data, hour: moment()})
	}

	const autoUpdateValues = (field, value) => {
		switch(field){
			case "name":
				if(value !== undefined){
					setData({...data, name: value})
				}
				break;
				case "position":
				if(value !== undefined){
					setData({...data, position: value})
				}
				break;
				case "phone":
				if(value !== undefined){
					setData({...data, phone: value})
				}
				break;
				case "email":
				if(value !== undefined){
					setData({...data, email: value})
				}
				break;
			case "activity_name":
				if(value !== undefined){
					setData({...data, activity_name: value})
				}
				break;
			case "speaker_name":
				if(value !== undefined){
					setData({...data, speaker_name: value})
				}
				break;
			case "duration":
				if(value !== undefined){
					setData({...data, duration: value})
				}
				break;
			case "age":
				if(value !== undefined){
					setData({...data, age: value})
				}
				break;
		}
	}

	const validateFields = () => {
		if(data.state === undefined){
			message.warn("Seleccione un estado");
			return false;
		}
		if(data.name === ""){
			message.warn("Ingrese el nombre");
			return false;
		}
		if(data.position === ""){
			message.warn("Ingrese el cargo");
			return false;
		}
		if(data.phone === ""){
			message.warn("Ingrese el número telefónico");
			return false;
		}
		if(data.email === ""){
			message.warn("Ingrese el correo electrónico");
			return false;
		}
		if(data.activity_name === ""){
			message.warn("Ingrese el nombre de la actividad");
			return false;
		}
		if(data.speaker_name === ""){
			message.warn("Ingrese el nombre del expositor/tallerista");
			return false;
		}
		if(data.duration === ""){
			message.warn("Ingrese la duración de la actividad");
			return false;
		}
		if(data.age === ""){
			message.warn("Ingrese las edades");
			return false;
		}
		if(data.hour === "00"){
			message.warn("Ingrese una hora válida");
			return false;
		}
		//validar que day & hour no están ocupados
		return true;
	}

	const handleSelectDate = (value) => {
		let refValue = moment(value).format('DD');
		let hoursOTD = registers.filter(frm=>frm.day === refValue).map(fm=>Number(fm.hour));
		if(refValue === "07"){
			setDisabledHours([...hoursOTD, 0, 1, 2, 3, 4, 5 , 6, 7, 8, 9, 21, 22, 23])
		}else{
			setDisabledHours([...hoursOTD, 0, 1, 2, 3, 4, 5 , 6, 7, 21, 22, 23])
		}
		//console.log('value', value);
		//console.log('hour', moment(value).format("HH"));
		//console.log('dateTime', moment(value).unix());
		//console.log('dateTimeString', moment(value).format());
		let centroMexico = `${moment(value).format("YYYY")}-${moment(value).format("MM")}-${moment(value).format("DD")}T${moment(value).format("HH")}:00:00-06:00`;
		//console.log('centroMexico', moment(centroMexico).format());
		setData({...data, hour: moment(value).format("HH"), day: moment(value).format("DD"), dateTime: moment(value).unix(), dateTimeString: moment(value).format()})
	}

	return (
		<div className="body-container">
		  <div className="header-logos card-div">
		  	<img src={sncVertical} height={140}/>
		  	<img src={cocytiegVertical} height={140}/>
		  	<img src={rednacecytVertical} height={140}/>
		  </div>

		  <div className="title-form card-div">
		    <div className="title-form-card-div-container">
		      <span className="title">Semana Nacional del Conocimiento 2022 "Ciencias Básicas para el Desarrollo Sostenible"</span>            
		      {
		      	//!stateSelected &&
		      	<span className="advertising">Favor de realizar un registro por cada actividad propuesta que coordinará cada Estado.</span>
		      }
		      <Divider/>
		      <span className="obligatorio">* Obligatorio</span>
		    </div>
		  </div>

		  {
		  	//!stateSelected &&
		  	<div className="estado-div card-div">
		  	  <div className="estado-div-container"> 
		  	    <span>Estado:<span className="required">*</span></span>
		  	    <Select value={data.state} style={{ width: 250, marginTop: "12px" }} placeholder="Elige" onChange={handleStateChange}>
		  	      {
		  	        estados.map((estado, index)=>{
		  	          return(
		  	            <Option key={index} value={estado.nombre}>{estado.nombre}</Option>
		  	          )
		  	        })
		  	      }
		  	    </Select>
		  	  </div>
		  	</div>
		  }

		  {
		  	/*
		  	//stateSelected &&
		  	<div className="actividad-propuesta-div card-div">
		  		<div className="actividad-propuesta-div-container">
		  			<span>Favor de realizar un registro por cada actividad propuesta que coordinará cada Estado.</span>
		  		</div>
		  	</div>*/
		  }

		  {
		  	//stateSelected &&
		  	<div className="nombre-actividad-div card-div">
		  		<div className="nombre-actividad-div-container">
		  			<span className="field">Nombre del enlace designado para coordinar la actividad propuesta:<span className="required">*</span></span>
		  			<Input value={data.name} className="input-form" onChange={e=>autoUpdateValues("name", e.target.value)}/>
		  		</div>
		  	</div>
		  }

		  {
		  	//stateSelected &&
		  	<div className="nombre-actividad-div card-div">
		  		<div className="nombre-actividad-div-container">
		  			<span className="field">Cargo:<span className="required">*</span></span>
		  			<Input value={data.position} className="input-form" onChange={e=>autoUpdateValues("position", e.target.value)}/>
		  		</div>
		  	</div>
		  }

		  {
		  	//stateSelected &&
		  	<div className="nombre-actividad-div card-div">
		  		<div className="nombre-actividad-div-container">
		  			<span className="field">Teléfono:<span className="required">*</span></span>
		  			{/*<Input value={data.phone} className="input-form" onChange={e=>autoUpdateValues("phone", e.target.value)}/>*/}
		  			<NumberFormat type="text"  className={` ant-input  number-format-tel`}
			          				format="(###) ### ####" value={data.phone} placeholder={'Teléfono'}
		            				allowNegative={false} allowLeadingZeros={false} 
		            				onValueChange={ (txt) => autoUpdateValues( "phone", txt.formattedValue ) }  />
		  		</div>
		  	</div>
		  }

		  {
		  	//stateSelected &&
		  	<div className="nombre-actividad-div card-div">
		  		<div className="nombre-actividad-div-container">
		  			<span className="field">Correo electrónico:<span className="required">*</span></span>
		  			<Input value={data.email} className="input-form" onChange={e=>autoUpdateValues("email", e.target.value)}/>
		  		</div>
		  	</div>
		  }

		  {
		  	//stateSelected &&
		  	<div className="nombre-actividad-div card-div">
		  		<div className="nombre-actividad-div-container">
		  			<span className="field">Actividad que aportará a la 2da Semana Nacional del Conocimiento:<span className="required">*</span></span>
		  			<Input value={data.activity_name} className="input-form" onChange={e=>autoUpdateValues("activity_name", e.target.value)}/>
		  		</div>
		  	</div>
		  }

		  {
		  	////stateSelected &&
		  	<div className="nombre-actividad-div card-div">
		  		<div className="nombre-actividad-div-container">
		  			<span className="field">Modalidad de la actividad:<span className="required">*</span></span>
		  			<Radio.Group onChange={onChangeModalidad} value={data.mode}>
		  				<Space direction="vertical">
		  					<Radio value={1}>Presencial</Radio>
		  			    	<Radio value={2}>Virtual</Radio>
		  			    </Space>
		  			</Radio.Group>
		  		</div>
		  	</div>
		  }

		  {
		  	//stateSelected &&
		  	<div className="nombre-actividad-div card-div">
		  		<div className="nombre-actividad-div-container">
		  			<span className="field">Speakers, Expositores o Talleristas que participarán:<span className="required">*</span></span>
		  			<Input value={data.speaker_name} className="input-form" onChange={e=>autoUpdateValues("speaker_name", e.target.value)}/>
		  		</div>
		  	</div>
		  }

		  {
		  	//stateSelected &&
		  	<div className="nombre-actividad-div card-div">
		  		<div className="nombre-actividad-div-container">
		  			<span className="field">Tiempo de duración de la actividad:<span className="required">*</span></span>
		  			<Input value={data.duration} className="input-form" onChange={e=>autoUpdateValues("duration", e.target.value)}/>
		  		</div>
		  	</div>
		  }

		  {
		  	//stateSelected &&
		  	<div className="nombre-actividad-div card-div">
		  		<div className="nombre-actividad-div-container">
		  			<span className="field">Edades a las que está dirigida la actividad:<span className="required">*</span></span>
		  			<Input value={data.age} className="input-form" onChange={e=>autoUpdateValues("age", e.target.value)}/>
		  		</div>
		  	</div>
		  }

		  {
		  	//stateSelected &&
		  	<div className="nombre-actividad-div card-div">
		  		<div className="nombre-actividad-div-container">
		  			<span className="field">Fecha propuesta para el desarrollo de la actividad dentro de la Semana Nacional del Conocimiento (del 7 al 11 de noviembre):<span className="required">*</span></span>
		  			<span className="field">Seleccione hora del centro de México</span>
		  			<DatePicker
		  			      format="DD-MM-YYYY HH:mm"
		  			      value={moment(`202211${data.day}${data.hour}`, "YYYYMMDDHH")}
		  			      disabledDate={disabledDate}
		  			      disabledTime={disabledDateTime}
		  			      showTime={{
		  			        defaultValue: moment('08:00:00', 'HH:mm:ss'),
		  			      }}
		  			      showNow={false}
		  			      inputReadOnly={true}
		  			      onOk={handleDayHour}
		  			      onChange={handleDateChange}
		  			      onSelect={handleSelectDate}
		  			    />
		  		</div>
		  	</div>
		  }

		  <div className="buttons-div">
		  	<div className="buttons-div-container">
		  		{
		  			/*!stateSelected &&
		  			<Button onClick={handleSiguiente}>Siguiente</Button>*/
		  		}
		  		{
		  			/*stateSelected &&
		  			<div className="buttons-action">
		  				<Space>
		  					<Button onClick={handleBack}>Atrás</Button>
		  					<Button onClick={handleSend}>Enviar</Button>
		  				</Space>
		  			</div>*/
		  		}
		  		<Button onClick={handleSend}>Enviar</Button>
		  		{/*<Button>Borrar formulario</Button>*/}
		  	</div>
		  </div>
		</div>
	)
}

export default Home