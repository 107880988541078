import { useState, useEffect } from 'react';
import { useNavigate  } from "react-router-dom";
import { Input, message } from 'antd'; 
import Model from '../../model';
import './stilos.scss';
import { InputContainer } from '../../componentes';

const key = 'updatable';
function Login() {
  let navigate = useNavigate();
  const [dataLogin,setDataLogin] = useState({email: '',password: ''});
  useEffect(()=>{
    Model.observoStatusLogeo(examinoExistencia);
    // eslint-disable-next-line
  },[]);

  const examinoExistencia = (user) => { 
    if (!user) {
    }else{
      message.success({ content:'¡Bienvenido!', key });
      navigate('/dashboard',{replace:true})
    }
  }
  const handleChange=(prop,valor)=>{
    setDataLogin({...dataLogin,[prop]:valor})
  }

  const logueo =async()=>{ 
    if(dataLogin.email.trim() === '' ||  dataLogin.password.trim() === ''){
      message.warning('Ingrese un usuario válido');
      return;
    }
    message.loading({ content: 'Cargando...', key });
    let rt = await Model.inicioSesion(dataLogin); 
    if (rt.error) {
      message.error({ content: 'Acceso denegado', key }); 
    } 
  }  
  return(
    <div className="login-container">
      <div className="card-login">
        <div className="card-login-header">
          <h1>Login</h1>
        </div>
        <div className="card-login-body">
          <InputContainer label="Correo electrónico" >
            <Input 
              value={dataLogin.email}
              type="email" name="email"
              onChange={event=>handleChange('email',event.target.value)}
            />
          </InputContainer>
          <InputContainer label="Contraseña" >
            <Input.Password
              value={dataLogin.password}
              name="password"
              onPressEnter={logueo}
              onChange={event=>handleChange('password',event.target.value)}
            />
          </InputContainer>
          <button  onClick={logueo}>
            Iniciar sesión
          </button> 
        </div>
      </div>
    </div>
  );
}
 

export default Login;